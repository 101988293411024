import React, { Component } from "react";
import { connect } from "react-redux";

import { Rodape } from "../componente/rodape";
import ReactPlayer from "react-player";
import "./Home.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import { Cabecalho } from "../componente/cabecalho";
import Carroussel from "../componente/Carroussel";
import Navbar from "../componente/Navbar";
import { RiArrowRightUpLine } from "react-icons/ri";
export function HomePage({ history }) {
  return (
    <div className=" bg-neutral-900">
      <div className="mx-auto  px-6 pb-8 lg:px-16 pt-5 md:pt-10  ">
        <Navbar />

        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="flex flex-col gap-4 justify-center items-center mt-14 md:mt-20">
              <h1 className="leading-tight md:leading-none font-bold text-neutral-100 text-[42px] md:text-[64px] text-start md:text-center">
                Use a IA para criar referências<br className="hidden md:block" /> ou desenhos finais
                para seus trabalhos
              </h1>
              <p className="text-lg md:text-xl text-neutral-400 font-normal text-start md:text-center">
                A capacidade de criar desenhos com base em descrições fornecidas por texto, esboços ou rascunhos.<br className="hidden md:block" />
                Pode ser utilizada em várias situações, como superar bloqueios criativos ou para agilmente apresentar<br className="hidden md:block" />
                 uma referência a um cliente. Com essa funcionalidade, você pode obter rapidamente uma representação visual do<br className="hidden md:block" />
                 conceito desejado, facilitando a comunicação com seus clientes e agilizando o processo de criação de tatuagens.
              </p>
              <div className="flex flex-col-reverse md:flex-col gap-3">
                <div className="hidden md:flex flex-col md:flex-row justify-center items-center gap-1 md:gap-3">
                  <button className="flex items-center justify-center gap-3 w-full md:w-60 bg-transparent border-2 border-neutral-300 rounded-lg p-3 text-neutral-300 text-base font-semibold mt-3 hover:bg-neutral-800 focus:border-2 focus:border-green-700 ">
                    Agende uma demonstração
                  </button>
                  <button className="flex items-center justify-center w-full md:w-60 gap-2 bg-neutral-100 rounded-lg p-3 text-neutral-800 text-base font-bold mt-3 hover:bg-neutral-300 focus:border-2 border-green-700">
                    Teste grátis por 10 dias{" "}
                    <RiArrowRightUpLine className="w-5 h-5" />
                  </button>
                </div>
                <img
                  src="/telas/AI.svg"
                  alt="Tela de AI"
                  className="max-w-6xl object-cover mt-5"
                />
              </div>
            </div>
          </SwiperSlide>
        {/*  <SwiperSlide>
            <div className="flex flex-col gap-4 justify-center items-center mt-14 md:mt-20">
              <h1 className="leading-tight md:leading-none font-bold text-neutral-100 text-[42px] md:text-[64px] text-start md:text-center">
                Crie desenhos únicos com ajuda{" "}
                <br className="hidden md:block" /> da inteligência artificial
              </h1>
              <p className="text-lg md:text-xl text-neutral-400 font-normal text-start md:text-center">
                Usando algoritmos de aprendizado de máquina, gera tatuagens
                personalizadas com base <br className="hidden md:block" /> em
                padrões populares e ajustáveis às preferências dos clientes.
              </p>
              <div className="flex flex-col-reverse md:flex-col gap-3">
                <div className="hidden md:flex flex-col md:flex-row justify-center items-center gap-1 md:gap-3">
                  <button className="flex items-center justify-center gap-3 w-full md:w-60 bg-transparent border-2 border-neutral-300 rounded-lg p-3 text-neutral-300 text-base font-semibold mt-3 hover:bg-neutral-800 focus:border-2 focus:border-green-700 ">
                    Agende uma demonstração
                  </button>
                  <button className="flex items-center justify-center w-full md:w-60 gap-2 bg-neutral-100 rounded-lg p-3 text-neutral-800 text-base font-bold mt-3 hover:bg-neutral-300 focus:border-2 border-green-700">
                    Teste grátis por 10 dias{" "}
                    <RiArrowRightUpLine className="w-5 h-5" />
                  </button>
                </div>
                <img
                  src="/telas/Atendimento.svg"
                  alt="Tela de Atendimento"
                  className="max-w-6xl object-cover mt-5 "
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col gap-4 justify-center items-center mt-14 md:mt-20">
              <h1 className="leading-tight md:leading-none font-bold text-neutral-100 text-[42px] md:text-[64px] text-start md:text-center">
                Crie desenhos únicos com ajuda{" "}
                <br className="hidden md:block" /> da inteligência artificial
              </h1>
              <p className="text-lg md:text-xl text-neutral-400 font-normal text-start md:text-center">
                Usando algoritmos de aprendizado de máquina, gera tatuagens
                personalizadas com base <br className="hidden md:block" /> em
                padrões populares e ajustáveis às preferências dos clientes.
              </p>
              <div className="flex flex-col-reverse md:flex-col gap-3">
                <div className="hidden md:flex flex-col md:flex-row justify-center items-center gap-1 md:gap-3">
                  <button className="flex items-center justify-center gap-3 w-full md:w-60 bg-transparent border-2 border-neutral-300 rounded-lg p-3 text-neutral-300 text-base font-semibold mt-3 hover:bg-neutral-800 focus:border-2 focus:border-green-700 ">
                    Agende uma demonstração
                  </button>
                  <button className="flex items-center justify-center w-full md:w-60 gap-2 bg-neutral-100 rounded-lg p-3 text-neutral-800 text-base font-bold mt-3 hover:bg-neutral-300 focus:border-2 border-green-700">
                    Teste grátis por 10 dias{" "}
                    <RiArrowRightUpLine className="w-5 h-5" />
                  </button>
                </div>
                <img
                  src="/telas/Dashboard.svg"
                  alt="Dashboard"
                  className="max-w-6xl object-cover mt-5"
                />
              </div>
            </div>
          </SwiperSlide>*/}
          <SwiperSlide>
            <div className="flex flex-col gap-4 justify-center items-center mt-14 md:mt-20">
              <h1 className="leading-tight md:leading-none font-bold text-neutral-100 text-[42px] md:text-[64px] text-start md:text-center">
                Organize e otimize a agenda do <br className="hidden md:block" />seu estúdio com inteligência artificial
              </h1>
              <p className="text-lg md:text-xl text-neutral-400 font-normal text-start md:text-center">
                Simplifique sua rotina de trabalho e reduza o estresse do gerenciamento de horários com nossa solução eficiente de automação. <br className="hidden md:block" />Com apenas alguns cliques, você poderá gerenciar horários, agendar compromissos, confirmar reservas e enviar lembretes <br className="hidden md:block" /> personalizados para seus clientes, tornando a experiência mais conveniente e agradável.
              </p>
              <div className="flex flex-col-reverse md:flex-col gap-3">
                <div className="hidden md:flex flex-col md:flex-row justify-center items-center gap-1 md:gap-3">
                  <button className="flex items-center justify-center gap-3 w-full md:w-60 bg-transparent border-2 border-neutral-300 rounded-lg p-3 text-neutral-300 text-base font-semibold mt-3 hover:bg-neutral-800 focus:border-2 focus:border-green-700 ">
                    Agende uma demonstração
                  </button>
                  <button className="flex items-center justify-center w-full md:w-60 gap-2 bg-neutral-100 rounded-lg p-3 text-neutral-800 text-base font-bold mt-3 hover:bg-neutral-300 focus:border-2 border-green-700">
                    Teste grátis por 10 dias{" "}
                    <RiArrowRightUpLine className="w-5 h-5" />
                  </button>
                </div>
                <img
                  src="/telas/Agenda.svg"
                  alt="Tela de Agenda"
                  className="max-w-6xl object-cover mt-5"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col gap-4 justify-center items-center mt-14 md:mt-20">
              <h1 className="leading-tight md:leading-none font-bold text-neutral-100 text-[42px] md:text-[64px] text-start md:text-center">
                Preencha e armazene suas fichas<br className="hidden md:block" /> de anamnese de forma digital e segura
              </h1>
              <p className="text-lg md:text-xl text-neutral-400 font-normal text-start md:text-center">
                Seus clientes recebem automaticamente, via WhatsApp, uma ficha de anamnese.
                Após o preenchimento e assinatura<br className="hidden md:block" /> digital, essa ficha é armazenada no perfil
                do cliente, garantindo segurança para o seu estúdio em conformidade com os órgãos reguladores.
              </p>
              <div className="flex flex-col-reverse md:flex-col gap-3">
                <div className="hidden md:flex flex-col md:flex-row justify-center items-center gap-1 md:gap-3">
                  <button className="flex items-center justify-center gap-3 w-full md:w-60 bg-transparent border-2 border-neutral-300 rounded-lg p-3 text-neutral-300 text-base font-semibold mt-3 hover:bg-neutral-800 focus:border-2 focus:border-green-700 ">
                    Agende uma demonstração
                  </button>
                  <button className="flex items-center justify-center w-full md:w-60 gap-2 bg-neutral-100 rounded-lg p-3 text-neutral-800 text-base font-bold mt-3 hover:bg-neutral-300 focus:border-2 border-green-700">
                    Teste grátis por 10 dias{" "}
                    <RiArrowRightUpLine className="w-5 h-5" />
                  </button>
                </div>
                <img
                  src="/telas/Ficha-Anamnese.svg"
                  alt="Tela de Cliente"
                  className="max-w-6xl object-cover mt-5"
                />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="flex flex-col gap-4 justify-center items-center mt-14 md:mt-20">
              <h1 className="leading-tight md:leading-none font-bold text-neutral-100 text-[42px] md:text-[64px] text-start md:text-center">
                Não perca mais nenhum orçamento{" "}
                <br className="hidden md:block" /> com ajuda da inteligência artificial
              </h1>
              <p className="text-lg md:text-xl text-neutral-400 font-normal text-start md:text-center">
                Após preencher as informações do seu estúdio e definir o método usado para os orçamentos dentro do TattooAI,<br className="hidden md:block" />
                você pode permitir que a Inteligência Artificial responda aos seus clientes automaticamente, 24 horas por dia.<br className="hidden md:block" />
                No entanto,você tem a opção de desligar o atendimento da IA a qualquer momento, seja de forma geral ou apenas<br className="hidden md:block" />
                em conversas específicas em que você prefere responder no lugar da IA.
              </p>
              <div className="flex flex-col-reverse md:flex-col gap-3">
                <div className="hidden md:flex flex-col md:flex-row justify-center items-center gap-1 md:gap-3">
                  <button className="flex items-center justify-center gap-3 w-full md:w-60 bg-transparent border-2 border-neutral-300 rounded-lg p-3 text-neutral-300 text-base font-semibold mt-3 hover:bg-neutral-800 focus:border-2 focus:border-green-700 ">
                    Agende uma demonstração
                  </button>
                  <button className="flex items-center justify-center w-full md:w-60 gap-2 bg-neutral-100 rounded-lg p-3 text-neutral-800 text-base font-bold mt-3 hover:bg-neutral-300 focus:border-2 border-green-700">
                    Teste grátis por 10 dias{" "}
                    <RiArrowRightUpLine className="w-5 h-5" />
                  </button>
                </div>
                <img
                  src="/telas/Orcamento.svg"
                  alt="Tela de Orçamento"
                  className="max-w-6xl object-cover mt-5 mb-12 md:mb-14"
                />
              </div>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className="flex flex-col gap-3 justify-center items-center mt-10">
              <h1 className="leading-none font-bold text-neutral-100 text-[64px] ">
                Crie desenhos únicos com ajuda <br /> da inteligência artificial
              </h1>
              <p className=" text-xl text-neutral-400 font-normal">
                Usando algoritmos de aprendizado de máquina, gera tatuagens
                personalizadas com base <br /> em padrões populares e ajustáveis
                às preferências dos clientes.
              </p>
              <div className="flex justify-center items-center gap-3">
                <button className="flex items-center justify-center gap-3 w-60 bg-transparent border-2 border-neutral-300 rounded-lg p-3 text-neutral-300 text-base font-semibold mt-3 hover:bg-neutral-800 focus:border-2 focus:border-green-700 ">
                  Entre em contato
                </button>
                <button className="flex items-center justify-center w-60 gap-2 bg-neutral-100 rounded-lg p-3 text-neutral-800 text-base font-bold mt-3 hover:bg-neutral-300 focus:border-2 border-green-700">
                  Ganhe um teste grátis <RiArrowRightUpLine className="w-5 h-5" />
                </button>
              </div>
              <img
                src="/telas/tela_ai.png"
                alt="AI"
                className="max-w-6xl object-cover mt-5"
              />
            </div>
          </SwiperSlide> */}
        </Swiper>
        <div className="md:hidden flex flex-col md:flex-row justify-center items-center gap-1 md:gap-3">
          <button className="flex items-center justify-center gap-3 w-full md:w-60 bg-transparent border-2 border-neutral-300 rounded-lg p-3 text-neutral-300 text-base font-semibold mt-3 hover:bg-neutral-800 focus:border-2 focus:border-green-700 ">
            Entre em contato
          </button>
          <button className="flex items-center justify-center w-full md:w-60 gap-2 bg-neutral-100 rounded-lg p-3 text-neutral-800 text-base font-bold mt-3 hover:bg-neutral-300 focus:border-2 border-green-700">
            Ganhe um teste grátis <RiArrowRightUpLine className="w-5 h-5" />
          </button>
        </div>
        {/*
                    <div className="mx-auto max-w-7xl px-6 pt-10 pb-24 sm:pb-32 lg:flex lg:py-10 lg:px-8">
                        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
                            <img className="h-12" src="/logo/SVG/Logo branca horizontal sem fundo.svg"
                                 alt="Your Company"/>
                            <div>
                                <h1 className="mt-[2em] text-4xl font-bold tracking-tight text-white sm:text-6xl">Orçamentos Garantidos com IA
                                    e não perca mais oportunidades</h1>
                                <p className="mt-6 text-lg leading-8 text-gray-300">Permite que seus clientes recebam cotações automatizadas e personalizadas de tatuagens através de um chat bot integrado com o WhatsApp. Com esta funcionalidade, seus clientes podem facilmente enviar imagens e descrições das tatuagens desejadas, e receber cotações precisas e rápidas, aumentando a eficiência do seu estúdio. Além disso, o chat bot também pode ser personalizado para responder perguntas frequentes e orientar seus clientes no processo de agendamento, otimizando a experiência do usuário e aumentando a satisfação do cliente.</p>
                                <div className="mt-10 flex items-center gap-x-6">
                                    <a href="#"
                                       className="rounded-md bg-neutral-800  px-5.5 py-1.5 text-2xl font-bold pr-8 pl-8 pt-5 pb-5 leading-7 text-white shadow-sm hover:bg-neutral-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-400">Teste gratis por 15 dias </a>

                                </div>
                            </div>
                        </div>
                        <div
                            className="mx-auto mt-16 flex max-w-1xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-12">
                            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                                <img src="/telas/tela_orcamento.png"
                                     alt="App screenshot"
                                     className="w-[70rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"/>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="mx-auto max-w-7xl px-6 pt-10 pb-24 sm:pb-32 lg:flex lg:py-10 lg:px-8">
                        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
                            <img className="h-12" src="/logo/SVG/Logo branca horizontal sem fundo.svg"
                                 alt="Your Company"/>
                            <div>
                                <h1 className="mt-[2em] text-4xl font-bold tracking-tight text-white sm:text-6xl">Armazene suas fichas anamnese</h1>
                                <p className="mt-6 text-lg leading-8 text-gray-300">Utiliza inteligência artificial para solicitar o preenchimento da ficha anamnese via chatbot no WhatsApp, antes da sessão de tatuagem, se necessário. Além disso, armazena o histórico completo das informações dos seus clientes de forma segura e organizada. Mantenha seu estúdio e seus clientes protegidos!.</p>
                                <div className="mt-10 flex items-center gap-x-6">
                                    <a href="#"
                                       className="rounded-md bg-neutral-800  px-5.5 py-1.5 text-2xl font-bold pr-8 pl-8 pt-5 pb-5 leading-7 text-white shadow-sm hover:bg-neutral-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-400">Teste gratis por 15 dias </a>

                                </div>
                            </div>
                        </div>
                        <div
                            className="mx-auto mt-16 flex max-w-1xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-12">
                            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                                <img src="/telas/tela_ficha.png"
                                     alt="App screenshot"
                                     className="w-[70rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"/>
                            </div>
                        </div>
                    </div>
                     <div className="mx-auto max-w-7xl px-6 pt-10 pb-24 sm:pb-32 lg:flex lg:py-10 lg:px-8">
                    <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
                        <img className="h-12" src="/logo/SVG/Logo branca horizontal sem fundo.svg"
                             alt="Your Company"/>
                        <div>
                            <h1 className="mt-[2em] text-4xl font-bold tracking-tight text-white sm:text-6xl">Crie desenhos unicos com ajuda da inteligencia artificial</h1>
                            <p className="mt-6 text-lg leading-8 text-gray-300">usa algoritmos de aprendizado de máquina para analisar os padrões de design mais populares e, com base nisso, gerar novas ideias de desenhos. Isso possibilita ao tatuador oferecer aos clientes uma experiência personalizada e única, garantindo que suas tatuagens sejam verdadeiramente originais. Além disso, a ferramenta permite ajustar os desenhos criados para atender às preferências específicas dos clientes.</p>
                            <div className="mt-10 flex items-center gap-x-6">
                                <a href="#"
                                   className="rounded-md bg-neutral-800  px-5.5 py-1.5 text-2xl font-bold pr-8 pl-8 pt-5 pb-5 leading-7 text-white shadow-sm hover:bg-neutral-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-400">Teste gratis por 15 dias </a>

                            </div>
                        </div>
                    </div>
                    <div
                        className="mx-auto mt-16 flex max-w-1xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-12">
                        <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                            <img src="/telas/tela_ai.png"
                                 alt="App screenshot"
                                 className="w-[70rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"/>
                        </div>
                    </div>
                </div>*/}
      </div>

      <div className="bg-neutral-800">
        <div className="mx-auto max-w-7xl py-12 px-6 sm:py-16 lg:px-8 lg:py-20">
          <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl">
              Assista como inteligencia artifical pode lhe ajudar
            </h2>
            <p className="mt-3 text-xl text-neutral-400 sm:mt-4">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Repellendus repellat laudantium.
            </p>
          </div>
          <div className="mx-auto max-w-4xl mt-10 text-center">
            <ReactPlayer
              width="860px"
              height="460px"
              url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
            />
          </div>
        </div>
      </div>
      <div className="bg-neutral-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-lg font-semibold leading-8 tracking-tight text-neutral-400">
              Everything you need
            </h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
              No server? No problem.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores
              impedit perferendis suscipit eaque, iste dolor cupiditate
              blanditiis.
            </p>
          </div>
        </div>
        <div className="relative overflow-hidden pt-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <img
              src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
              alt="App screenshot"
              className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-white/10"
              width="2432"
              height="1442"
            />
            <div className="relative" aria-hidden="true">
              <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-gray-900 pt-[7%]"></div>
            </div>
          </div>
        </div>
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
          <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <svg
                  className="absolute top-1 left-1 h-5 w-5 text-neutral-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z"
                    clip-rule="evenodd"
                  />
                </svg>
                Push to deploy.
              </dt>
              <dd className="inline">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id
                magna.
              </dd>
            </div>

            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <svg
                  className="absolute top-1 left-1 h-5 w-5 text-neutral-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z"
                    clip-rule="evenodd"
                  />
                </svg>
                SSL certificates.
              </dt>
              <dd className="inline">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
                lorem cupidatat commodo.
              </dd>
            </div>

            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <svg
                  className="absolute top-1 left-1 h-5 w-5 text-neutral-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
                    clip-rule="evenodd"
                  />
                </svg>
                Simple queues.
              </dt>
              <dd className="inline">
                Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.
              </dd>
            </div>

            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <svg
                  className="absolute top-1 left-1 h-5 w-5 text-neutral-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 2.5c-1.31 0-2.526.386-3.546 1.051a.75.75 0 01-.82-1.256A8 8 0 0118 9a22.47 22.47 0 01-1.228 7.351.75.75 0 11-1.417-.49A20.97 20.97 0 0016.5 9 6.5 6.5 0 0010 2.5zM4.333 4.416a.75.75 0 01.218 1.038A6.466 6.466 0 003.5 9a7.966 7.966 0 01-1.293 4.362.75.75 0 01-1.257-.819A6.466 6.466 0 002 9c0-1.61.476-3.11 1.295-4.365a.75.75 0 011.038-.219zM10 6.12a3 3 0 00-3.001 3.041 11.455 11.455 0 01-2.697 7.24.75.75 0 01-1.148-.965A9.957 9.957 0 005.5 9c0-.028.002-.055.004-.082a4.5 4.5 0 018.996.084V9.15l-.005.297a.75.75 0 11-1.5-.034c.003-.11.004-.219.005-.328a3 3 0 00-3-2.965zm0 2.13a.75.75 0 01.75.75c0 3.51-1.187 6.745-3.181 9.323a.75.75 0 11-1.186-.918A13.687 13.687 0 009.25 9a.75.75 0 01.75-.75zm3.529 3.698a.75.75 0 01.584.885 18.883 18.883 0 01-2.257 5.84.75.75 0 11-1.29-.764 17.386 17.386 0 002.078-5.377.75.75 0 01.885-.584z"
                    clip-rule="evenodd"
                  />
                </svg>
                Advanced security.
              </dt>
              <dd className="inline">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id
                magna.
              </dd>
            </div>

            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <svg
                  className="absolute top-1 left-1 h-5 w-5 text-neutral-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.84 1.804A1 1 0 018.82 1h2.36a1 1 0 01.98.804l.331 1.652a6.993 6.993 0 011.929 1.115l1.598-.54a1 1 0 011.186.447l1.18 2.044a1 1 0 01-.205 1.251l-1.267 1.113a7.047 7.047 0 010 2.228l1.267 1.113a1 1 0 01.206 1.25l-1.18 2.045a1 1 0 01-1.187.447l-1.598-.54a6.993 6.993 0 01-1.929 1.115l-.33 1.652a1 1 0 01-.98.804H8.82a1 1 0 01-.98-.804l-.331-1.652a6.993 6.993 0 01-1.929-1.115l-1.598.54a1 1 0 01-1.186-.447l-1.18-2.044a1 1 0 01.205-1.251l1.267-1.114a7.05 7.05 0 010-2.227L1.821 7.773a1 1 0 01-.206-1.25l1.18-2.045a1 1 0 011.187-.447l1.598.54A6.993 6.993 0 017.51 3.456l.33-1.652zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"
                  />
                </svg>
                Powerful API.
              </dt>
              <dd className="inline">
                Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui
                lorem cupidatat commodo.
              </dd>
            </div>

            <div className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <svg
                  className="absolute top-1 left-1 h-5 w-5 text-neutral-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M4.632 3.533A2 2 0 016.577 2h6.846a2 2 0 011.945 1.533l1.976 8.234A3.489 3.489 0 0016 11.5H4c-.476 0-.93.095-1.344.267l1.976-8.234z" />
                  <path
                    fill-rule="evenodd"
                    d="M4 13a2 2 0 100 4h12a2 2 0 100-4H4zm11.24 2a.75.75 0 01.75-.75H16a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75h-.01a.75.75 0 01-.75-.75V15zm-2.25-.75a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75H13a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75h-.01z"
                    clip-rule="evenodd"
                  />
                </svg>
                Database backups.
              </dt>
              <dd className="inline">
                Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <section className="bg-neutral-800">
        <div className="mx-auto max-w-7xl md:grid md:grid-cols-2 md:px-6 lg:px-8">
          <div className="py-12 px-6 md:flex md:flex-col md:border-r md:border-neutral-900 md:py-16 md:pl-0 md:pr-10 lg:pr-16">
            <div className="md:flex-shrink-0">
              <img
                className="h-12"
                src="https://tailwindui.com/img/logos/tuple-logo-indigo-300.svg"
                alt="Tuple"
              />
            </div>
            <blockquote className="mt-6 md:flex md:flex-grow md:flex-col">
              <div className="relative text-lg font-medium text-white md:flex-grow">
                <svg
                  className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-neutral-600"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="relative">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                  expedita voluptas culpa sapiente alias molestiae. Numquam
                  corrupti in laborum sed rerum et corporis.
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex items-start">
                  <div className="inline-flex flex-shrink-0 rounded-full border-2 border-white">
                    <img
                      className="h-12 w-12 rounded-full"
                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-4">
                    <div className="text-base font-medium text-white">
                      Judith Black
                    </div>
                    <div className="text-base font-medium text-neutral-200">
                      CEO, Tuple
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
          <div className="border-t-2 border-neutral-900 py-12 px-6 md:border-t-0 md:border-l md:py-16 md:pr-0 md:pl-10 lg:pl-16">
            <div className="md:flex-shrink-0">
              <img
                className="h-12"
                src="https://tailwindui.com/img/logos/workcation-logo-indigo-300.svg"
                alt="Workcation"
              />
            </div>
            <blockquote className="mt-6 md:flex md:flex-grow md:flex-col">
              <div className="relative text-lg font-medium text-white md:flex-grow">
                <svg
                  className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-neutral-600"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                <p className="relative">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                  expedita voluptas culpa sapiente alias molestiae. Numquam
                  corrupti in laborum sed rerum et corporis. Nemo expedita
                  voluptas culpa sapiente alias molestiae.
                </p>
              </div>
              <footer className="mt-8">
                <div className="flex items-start">
                  <div className="inline-flex flex-shrink-0 rounded-full border-2 border-white">
                    <img
                      className="h-12 w-12 rounded-full"
                      src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-4">
                    <div className="text-base font-medium text-white">
                      Joseph Rodriguez
                    </div>
                    <div className="text-base font-medium text-neutral-200">
                      CEO, Workcation
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>

      <div class="bg-neutral-900 py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-4xl text-center">
            <h2 class="text-lg font-semibold leading-8 tracking-tight text-neutral-500">
              Pricing
            </h2>
            <p class="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              Pricing plans for teams of&nbsp;all&nbsp;sizes
            </p>
          </div>
          <p class="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-300">
            Choose an affordable plan that’s packed with the best features for
            engaging your audience, creating customer loyalty, and driving
            sales.
          </p>
          <div class="mt-16 flex justify-center">
            <fieldset class="grid grid-cols-2 gap-x-1 rounded-full bg-white/5 p-1 text-center text-xs font-semibold leading-5 text-white">
              <legend class="sr-only">Payment frequency</legend>

              <label class="cursor-pointer rounded-full py-1 px-2.5">
                <input
                  type="radio"
                  name="frequency"
                  value="monthly"
                  class="sr-only"
                />
                <span>Monthly</span>
              </label>

              <label class="cursor-pointer rounded-full py-1 px-2.5">
                <input
                  type="radio"
                  name="frequency"
                  value="annually"
                  class="sr-only"
                />
                <span>Annually</span>
              </label>
            </fieldset>
          </div>
          <div class="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div class="rounded-3xl p-8 xl:p-10 ring-1 ring-white/10">
              <div class="flex items-center justify-between gap-x-4">
                <h3
                  id="tier-freelancer"
                  class="text-lg font-semibold leading-8 text-white"
                >
                  Freelancer
                </h3>
              </div>
              <p class="mt-4 text-sm leading-6 text-gray-300">
                The essentials to provide your best work for clients.
              </p>
              <p class="mt-6 flex items-baseline gap-x-1">
                <span class="text-4xl font-bold tracking-tight text-white">
                  $15
                </span>

                <span class="text-sm font-semibold leading-6 text-gray-300">
                  /month
                </span>
              </p>
              <a
                href="#"
                aria-describedby="tier-freelancer"
                class="mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white"
              >
                Buy plan
              </a>
              <ul
                role="list"
                class="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10"
              >
                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  5 products
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Up to 1,000 subscribers
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Basic analytics
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  48-hour support response time
                </li>
              </ul>
            </div>

            <div class="rounded-3xl p-8 xl:p-10 bg-white/5 ring-2 ring-neutral-500">
              <div class="flex items-center justify-between gap-x-4">
                <h3
                  id="tier-startup"
                  class="text-lg font-semibold leading-8 text-white"
                >
                  Startup
                </h3>

                <p class="rounded-full bg-neutral-900 py-1 px-2.5 text-xs font-semibold leading-5 text-white">
                  Most popular
                </p>
              </div>
              <p class="mt-4 text-sm leading-6 text-gray-300">
                A plan that scales with your rapidly growing business.
              </p>
              <p class="mt-6 flex items-baseline gap-x-1">
                <span class="text-4xl font-bold tracking-tight text-white">
                  $30
                </span>
                <span class="text-sm font-semibold leading-6 text-gray-300">
                  /month
                </span>
              </p>
              <a
                href="#"
                aria-describedby="tier-startup"
                class="mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-neutral-900 text-white shadow-sm hover:bg-neutral-700 focus-visible:outline-neutral-800"
              >
                Buy plan
              </a>
              <ul
                role="list"
                class="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10"
              >
                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  25 products
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Up to 10,000 subscribers
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Advanced analytics
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  24-hour support response time
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Marketing automations
                </li>
              </ul>
            </div>

            <div class="rounded-3xl p-8 xl:p-10 ring-1 ring-white/10">
              <div class="flex items-center justify-between gap-x-4">
                <h3
                  id="tier-enterprise"
                  class="text-lg font-semibold leading-8 text-white"
                >
                  Enterprise
                </h3>
              </div>
              <p class="mt-4 text-sm leading-6 text-gray-300">
                Dedicated support and infrastructure for your company.
              </p>
              <p class="mt-6 flex items-baseline gap-x-1">
                <span class="text-4xl font-bold tracking-tight text-white">
                  $48
                </span>
                <span class="text-sm font-semibold leading-6 text-gray-300">
                  /month
                </span>
              </p>
              <a
                href="#"
                aria-describedby="tier-enterprise"
                class="mt-6 block rounded-md py-2 px-3 text-center text-sm leading-6 font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white"
              >
                Buy plan
              </a>
              <ul
                role="list"
                class="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10"
              >
                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Unlimited products
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Unlimited subscribers
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Advanced analytics
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  1-hour, dedicated support response time
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Marketing automations
                </li>

                <li class="flex gap-x-3">
                  <svg
                    class="h-6 w-5 flex-none text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  Custom reporting tools
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Rodape />
    </div>
  );
}
