import { RiArrowDropDownLine } from "react-icons/ri";

export default function Navbar() {
  return (
    <nav className="mx-auto flex items-center justify-between w-full z-10">
      <div className="flex items-center gap-12">
        <img
          className="h-12 md:12"
          src="/logo/SVG/Logo branca horizontal sem fundo.svg"
          alt="Your Company"
        />

      </div>
      <div className="flex items-center gap-2 md:gap-2.5">
        <div
          className="group relative inline-block text-left z-20"
          tabindex="-1"
        >
          <span className="rounded-md shadow-sm">
            <button
              className="active:shadow-outline-blue inline-flex items-center w-full py-1.5  px-1.5 md:px-2 h-fit text-sm font-normal text-neutral-500  hover:bg-neutral-800 rounded-md"
              type="button"
              aria-haspopup="true"
              // onClick={() => setIsOpen(!isOpen)}
            >
              <img
                src="/img/BR.svg"
                alt="Brasil"
                className="w-9 h-9 md:w-10 md:h-10"
              />
              <RiArrowDropDownLine className="w-8 h-8 ml-1 text-neutral-400" />
            </button>
          </span>
          {/* {isOpen ? ( */}
          <div className="invisible origin-top-right -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 group-focus-within:visible group-focus-within:translate-y-0 group-focus-within:scale-100 group-focus-within:opacity-100 z-50">
            <ul
              className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-neutral-700 rounded-md border border-neutral-700 bg-[#1e1e1e] shadow-lg outline-none"
              role="menu"
            >
              <li
                className="flex w-full gap-3 px-4 py-3 text-left text-xl leading-5 text-neutral-200 focus-visible:outline-1 hover:bg-neutral-800 cursor-pointer"
                role="menuitem"
              >
                <img  src="/flag/br.svg" alt="Bandeira do Brasil" />
                Brasil
              </li>

              <li
                className="flex w-full gap-3 px-4 py-3 text-left text-xl leading-5 text-neutral-200 focus-visible:outline-1 hover:bg-neutral-800 cursor-pointer"
                role="menuitem"
              >
                <img   src="/flag/us.svg" alt="Bandeira dos USA" />
                United States
              </li>
              <li
                  className="flex w-full gap-3 px-4 py-3 text-left text-xl leading-5 text-neutral-200 focus-visible:outline-1 hover:bg-neutral-800 cursor-pointer"
                  role="menuitem"
              >
                <img src="/flag/mx.svg" alt="Bandeira dos USA" />
                México
              </li>
              <li
                  className="flex w-full gap-3 px-4 py-3 text-left text-xl leading-5 text-neutral-200 focus-visible:outline-1 hover:bg-neutral-800 cursor-pointer"
                  role="menuitem"
              >
                <img src="/flag/pt.svg" alt="Bandeira dos USA" />
                Portugal
              </li>
              <li
                  className="flex w-full gap-3 px-4 py-3 text-left text-xl leading-5 text-neutral-200 focus-visible:outline-1 hover:bg-neutral-800 cursor-pointer"
                  role="menuitem"
              >
                <img src="/flag/es.svg" alt="Bandeira dos USA" />
                España
              </li>
            </ul>
          </div>
          {/* ) : null} */}
        </div>
        <button className="bg-neutral-100 px-4 md:px-5 py-2 md:py-2.5 rounded-md hover:bg-white font-bold text-base text-neutral-700">
          ENTRE NO APLICATIVO
        </button>
      </div>
    </nav>
  );
}
