import React, { Component } from 'react'
import { connect } from 'react-redux';

import {Rodape} from '../../componente/rodape';
import {Cabecalho} from "../../componente/cabecalho";
 export function CheckoutPage({ history }) {
     return (
            <>


                <div className="relative isolate overflow-hidden bg-white">


                    <div className="mx-auto max-w-7xl px-6 pt-1 pb-4 sm:pb-3 lg:flex lg:py-6 lg:px-8">
                        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-1">
                            <img className="h-12" src="/logo/SVG/Logo preta horizontal sem fundo.svg"
                                 alt="Your Company"/>

                        </div>
                        <div className="pl-20 w-full" aria-hidden="true">
                            <div className=" hidden grid-cols-3 text-xl font-medium text-gray-600 sm:grid">
                                <div className="text-center text-neutral-600">Dados basicos</div>
                                <div className="text-center">Checkout</div>
                                <div className="text-right">Finalizar</div>
                            </div>
                            <div className="overflow-hidden mt-4 rounded-full bg-neutral-200">
                                <div className="h-2 rounded-full bg-neutral-600 w-[66.6%]"></div>
                            </div>

                        </div>
                    </div>
                </div>




                <div class="bg-white">
                    <div class="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">





                            <form class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                            <div>
                                <div>
                                    <h2 class="text-2xl font-medium text-gray-900">Contact information</h2>

                                    <div class="mt-4">
                                        <label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
                                        <div class="mt-1">
                                            <input type="email" id="email-address" name="email-address" autocomplete="email" class="block w-full rounded-sm border-neutral-900   sm:text-md"/>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label htmlFor="phone"
                                               className="block text-sm font-medium text-gray-700">Phone</label>
                                        <div className="mt-1">
                                            <input type="text" name="phone" id="phone" autoComplete="tel"
                                                   className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                        </div>
                                    </div>
                                    <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                                        <div>
                                            <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
                                            <div class="mt-1">
                                                <input type="text" id="first-name" name="first-name" autocomplete="given-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>

                                        <div>
                                            <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                                            <div class="mt-1">
                                                <input type="text" id="last-name" name="last-name" autocomplete="family-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="company" class="block text-sm font-medium text-gray-700">Company</label>
                                            <div class="mt-1">
                                                <input type="text" name="company" id="company" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-2">
                                            <label for="address" class="block text-sm font-medium text-gray-700">Address</label>
                                            <div class="mt-1">
                                                <input type="text" name="address" id="address" autocomplete="street-address" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>


                                        <div>
                                            <label for="city" class="block text-sm font-medium text-gray-700">City</label>
                                            <div class="mt-1">
                                                <input type="text" name="city" id="city" autocomplete="address-level2" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>

                                        <div>
                                            <label for="country" class="block text-sm font-medium text-gray-700">Country</label>
                                            <div class="mt-1">
                                                <select id="country" name="country" autocomplete="country-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                                    <option>United States</option>
                                                    <option>Canada</option>
                                                    <option>Mexico</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div>
                                            <label for="region" class="block text-sm font-medium text-gray-700">State / Province</label>
                                            <div class="mt-1">
                                                <input type="text" name="region" id="region" autocomplete="address-level1" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>

                                        <div>
                                            <label for="postal-code" class="block text-sm font-medium text-gray-700">Postal code</label>
                                            <div class="mt-1">
                                                <input type="text" name="postal-code" id="postal-code" autocomplete="postal-code" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <div class="mt-10 border-t border-gray-200 pt-10">
                                    <h2 class="text-2xl font-medium text-gray-900">Payment</h2>

                                    <fieldset class="mt-4">
                                        <legend class="sr-only">Payment type</legend>
                                        <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                            <div class="flex items-center">
                                                <input id="credit-card" name="payment-type" type="radio" checked class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"/>
                                                    <label for="credit-card" class="ml-3 block text-sm font-medium text-gray-700">Credit card</label>
                                            </div>

                                            <div class="flex items-center">
                                                <input id="paypal" name="payment-type" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"/>
                                                    <label for="paypal" class="ml-3 block text-sm font-medium text-gray-700">PayPal</label>
                                            </div>

                                            <div class="flex items-center">
                                                <input id="etransfer" name="payment-type" type="radio" class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"/>
                                                    <label for="etransfer" class="ml-3 block text-sm font-medium text-gray-700">eTransfer</label>
                                            </div>
                                        </div>
                                    </fieldset>

                                    <div class="mt-6 grid grid-cols-4 gap-y-6 gap-x-4">
                                        <div class="col-span-4">
                                            <label for="card-number" class="block text-sm font-medium text-gray-700">Card number</label>
                                            <div class="mt-1">
                                                <input type="text" id="card-number" name="card-number" autocomplete="cc-number" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>

                                        <div class="col-span-4">
                                            <label for="name-on-card" class="block text-sm font-medium text-gray-700">Name on card</label>
                                            <div class="mt-1">
                                                <input type="text" id="name-on-card" name="name-on-card" autocomplete="cc-name" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>

                                        <div class="col-span-3">
                                            <label for="expiration-date" class="block text-sm font-medium text-gray-700">Expiration date (MM/YY)</label>
                                            <div class="mt-1">
                                                <input type="text" name="expiration-date" id="expiration-date" autocomplete="cc-exp" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>

                                        <div>
                                            <label for="cvc" class="block text-sm font-medium text-gray-700">CVC</label>
                                            <div class="mt-1">
                                                <input type="text" name="cvc" id="cvc" autocomplete="csc" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-2 lg:mt-0 ">
                                <h2 class="text-2xl font-medium text-gray-900">Order summary</h2>

                                <div class="mt-4 rounded-lg border border-gray-300 bg-neutral-100 shadow-sm">

                                    <div class="px-8 py-8">
                                        <fieldset>
                                            <legend className="text-2xl font-medium text-gray-900">Delivery method</legend>

                                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">

                                                <label
                                                    className="relative flex cursor-pointer rounded-lg  border-neutral-900 bg-neutral-200 p-4 shadow-sm focus:outline-none">
                                                    <input type="radio" name="delivery-method" value="Standard" className="sr-only"
                                                           aria-labelledby="delivery-method-0-label"
                                                           aria-describedby="delivery-method-0-description-0 delivery-method-0-description-1"/>
                                                    <span className="flex flex-1">
                                                      <span className="flex flex-col">
                                                        <span id="delivery-method-0-label"
                                                              className="block text-sm font-medium text-gray-900">Standard</span>
                                                        <span id="delivery-method-0-description-0" className="mt-1 flex items-center text-sm text-gray-500">4–10 business days</span>
                                                        <span id="delivery-method-0-description-1"
                                                              className="mt-6 text-sm font-medium text-gray-900">$5.00</span>
                                                      </span>
                                                    </span>

                                                    <svg className="h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                                              clip-rule="evenodd"/>
                                                    </svg>
                                                    <span className="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"></span>
                                                </label>

                                                <label
                                                    className="relative flex cursor-pointer rounded-lg border bg-neutral-50 p-4 shadow-sm focus:outline-none">
                                                    <input type="radio" name="delivery-method" value="Express" className="sr-only"
                                                           aria-labelledby="delivery-method-1-label"
                                                           aria-describedby="delivery-method-1-description-0 delivery-method-1-description-1"/>
                                                    <span className="flex flex-1">
                                                      <span className="flex flex-col">
                                                        <span id="delivery-method-1-label"
                                                              className="block text-sm font-medium text-gray-900">Express</span>
                                                        <span id="delivery-method-1-description-0" className="mt-1 flex items-center text-sm text-gray-500">2–5 business days</span>
                                                        <span id="delivery-method-1-description-1"
                                                              className="mt-6 text-sm font-medium text-gray-900">$16.00</span>
                                                      </span>
                                                    </span>
                                                    <svg className="h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                                              clip-rule="evenodd"/>
                                                    </svg>
                                                    <span className="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"></span>
                                                </label>
                                            </div>
                                            <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">

                                                <label
                                                    className="relative flex cursor-pointer rounded-lg   bg-neutral-50 p-4 shadow-sm focus:outline-none">
                                                    <input type="radio" name="delivery-method" value="Standard" className="sr-only"
                                                           aria-labelledby="delivery-method-0-label"
                                                           aria-describedby="delivery-method-0-description-0 delivery-method-0-description-1"/>
                                                    <span className="flex flex-1">
                                                      <span className="flex flex-col">
                                                        <span id="delivery-method-0-label"
                                                              className="block text-sm font-medium text-gray-900">Standard</span>
                                                        <span id="delivery-method-0-description-0" className="mt-1 flex items-center text-sm text-gray-500">4–10 business days</span>
                                                        <span id="delivery-method-0-description-1"
                                                              className="mt-6 text-sm font-medium text-gray-900">$5.00</span>
                                                      </span>
                                                    </span>

                                                    <svg className="h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                                              clip-rule="evenodd"/>
                                                    </svg>
                                                    <span className="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"></span>
                                                </label>

                                                <label
                                                    className="relative flex cursor-pointer rounded-lg border bg-neutral-50 p-4 shadow-sm focus:outline-none">
                                                    <input type="radio" name="delivery-method" value="Express" className="sr-only"
                                                           aria-labelledby="delivery-method-1-label"
                                                           aria-describedby="delivery-method-1-description-0 delivery-method-1-description-1"/>
                                                    <span className="flex flex-1">
                                                      <span className="flex flex-col">
                                                        <span id="delivery-method-1-label"
                                                              className="block text-sm font-medium text-gray-900">Express</span>
                                                        <span id="delivery-method-1-description-0" className="mt-1 flex items-center text-sm text-gray-500">2–5 business days</span>
                                                        <span id="delivery-method-1-description-1"
                                                              className="mt-6 text-sm font-medium text-gray-900">$16.00</span>
                                                      </span>
                                                    </span>
                                                    <svg className="h-5 w-5 text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fill-rule="evenodd"
                                                              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                                                              clip-rule="evenodd"/>
                                                    </svg>
                                                    <span className="pointer-events-none absolute -inset-px rounded-lg border-2" aria-hidden="true"></span>
                                                </label>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <dl class="space-y-6 border-t border-gray-200 py-6 px-4 sm:px-6">

                                        <div class="flex items-center justify-between pt-6">
                                            <dt class="text-base font-medium">Total</dt>
                                            <dd class="text-base font-medium text-gray-900">$75.52</dd>
                                        </div>
                                    </dl>

                                    <div class="border-t border-gray-200 py-6 px-4 sm:px-6">
                                        <button type="submit" class="w-full rounded-md border border-transparent bg-neutral-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Confirm order</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>




            </>
        );
}


