import React, { Component } from 'react'
import { connect } from 'react-redux';

import {Rodape} from '../../componente/rodape';
import {Cabecalho} from "../../componente/cabecalho";
export function DadosBasicoPage({ history }) {
    return (
        <>


            <div className="relative isolate overflow-hidden bg-white">


                <div className="mx-auto max-w-7xl px-6 pt-1 pb-4 sm:pb-3 lg:flex lg:py-6 lg:px-8">
                    <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-1">
                        <img className="h-12" src="/logo/SVG/Logo preta horizontal sem fundo.svg"
                             alt="Your Company"/>

                    </div>
                    <div className="pl-20 w-full" aria-hidden="true">
                        <div className=" hidden grid-cols-3 text-xl font-medium text-gray-600 sm:grid">
                            <div className="text-center text-neutral-600">Dados basicos</div>
                            <div className="text-center">Checkout</div>
                            <div className="text-right">Finalizar</div>
                        </div>
                        <div className="overflow-hidden mt-4 rounded-full bg-neutral-200">
                            <div className="h-2 rounded-full bg-neutral-600 w-[33.3%]"></div>
                        </div>

                    </div>
                </div>
            </div>




            <div class="bg-white">
                <div class="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">





                    <form class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
                        <div className="mt-2 lg:mt-0 ">


                            <div className="mt-4 rounded-lg border border-gray-300 bg-neutral-100 shadow-sm">

                                <div className="px-8 py-8">
                                    <fieldset>
                                        <legend className="text-2xl font-medium text-gray-900">Dados basico</legend>

                                        <div className="mt-4">
                                            <label htmlFor="email-address"
                                                   className="block text-sm font-medium text-gray-700">Email
                                                address</label>
                                            <div className="mt-1">
                                                <input type="email" id="email-address" name="email-address"
                                                       autoComplete="email"
                                                       className="block w-full rounded-sm border-neutral-900   sm:text-md"/>
                                            </div>
                                        </div>

                                        <div className="sm:col-span-2">
                                            <label htmlFor="phone"
                                                   className="block text-sm font-medium text-gray-700">Phone</label>
                                            <div className="mt-1">
                                                <input type="text" name="phone" id="phone" autoComplete="tel"
                                                       className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                                            </div>
                                        </div>

                                    </fieldset>
                                </div>

                                <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                                    <button type="submit"
                                            className="w-full rounded-md border border-transparent bg-neutral-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50">Proximo passo
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="mt-2 lg:mt-0 ">
                            <div className="bg-white">
                                <div class="bg-white">
                                    <div>
                                        <h2 class="sr-only">Customer Reviews</h2>

                                        <div class="-my-10">
                                            <div class="flex space-x-4 text-sm text-gray-500">
                                                <div class="flex-none py-10">
                                                    <img src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80" alt="" class="h-10 w-10 rounded-full bg-gray-100"/>
                                                </div>
                                                <div class="flex-1 py-10">
                                                    <h3 class="font-medium text-gray-900">Emily Selman</h3>
                                                    <p><time datetime="2021-07-16">July 16, 2021</time></p>

                                                    <div class="mt-4 flex items-center">
                                                        <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                                        </svg>

                                                        <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                                        </svg>

                                                        <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                                        </svg>

                                                        <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                                        </svg>

                                                        <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                                        </svg>
                                                    </div>
                                                    <p class="sr-only">5 out of 5 stars</p>

                                                    <div class="prose prose-sm mt-4 max-w-none text-gray-500">
                                                        <p>This icon pack is just what I need for my latest project. There's an icon for just about anything I could ever need. Love the playful look!</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="flex space-x-4 text-sm text-gray-500">
                                                <div class="flex-none py-10">
                                                    <img src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80" alt="" class="h-10 w-10 rounded-full bg-gray-100"/>
                                                </div>
                                                <div class="flex-1 py-10 border-t border-gray-200">
                                                    <h3 class="font-medium text-gray-900">Hector Gibbons</h3>
                                                    <p><time datetime="2021-07-12">July 12, 2021</time></p>

                                                    <div class="mt-4 flex items-center">
                                                        <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                                        </svg>

                                                        <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                                        </svg>

                                                        <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                                        </svg>

                                                        <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                                        </svg>

                                                        <svg class="text-yellow-400 h-5 w-5 flex-shrink-0" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd" />
                                                        </svg>
                                                    </div>
                                                    <p class="sr-only">5 out of 5 stars</p>

                                                    <div class="prose prose-sm mt-4 max-w-none text-gray-500">
                                                        <p>Blown away by how polished this icon pack is. Everything looks so consistent and each SVG is optimized out of the box so I can use it directly with confidence. It would take me several hours to create a single icon this good, so it's a steal at this price.</p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </form>
                </div>
            </div>




        </>
    );
}


