import React, { Component } from 'react'
import { connect } from 'react-redux';

import {Rodape} from '../componente/rodape';
import {Cabecalho} from "../componente/cabecalho";
 export function Error404Page({ history }) {
     return (
            <>
                <div className="relative isolate overflow-hidden bg-gray-900">
                    <svg
                        className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                        aria-hidden="true">
                        <defs>
                            <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1"
                                     patternUnits="userSpaceOnUse">
                                <path d="M.5 200V.5H200" fill="none"/>
                            </pattern>
                        </defs>
                        <svg x="50%" y="-1" className="overflow-visible fill-gray-800/20">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                stroke-width="0"/>
                        </svg>
                        <rect width="100%" height="100%" stroke-width="0"
                              fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"/>
                    </svg>
                    <svg viewBox="0 0 1108 632" aria-hidden="true"
                         className="absolute top-10 left-[calc(50%-4rem)] -z-10 w-[69.25rem] max-w-none transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]">
                        <path fill="url(#175c433f-44f6-4d59-93f0-c5c51ad5566d)" fill-opacity=".2"
                              d="M235.233 402.609 57.541 321.573.83 631.05l234.404-228.441 320.018 145.945c-65.036-115.261-134.286-322.756 109.01-230.655C968.382 433.026 1031 651.247 1092.23 459.36c48.98-153.51-34.51-321.107-82.37-385.717L810.952 324.222 648.261.088 235.233 402.609Z"/>
                        <defs>
                            <linearGradient id="175c433f-44f6-4d59-93f0-c5c51ad5566d" x1="1220.59" x2="-85.053"
                                            y1="432.766" y2="638.714" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#4F46E5"/>
                                <stop offset="1" stop-color="#80CAFF"/>
                            </linearGradient>
                        </defs>
                    </svg>
                    <div className="mx-auto max-w-7xl px-6 pt-10 pb-24 sm:pb-32 lg:flex lg:py-10 lg:px-8">
                        <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
                            <img className="h-16" src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                                 alt="Your Company"/>
                            <div className="mt-24 sm:mt-32 lg:mt-16">
                                <a href="#" className="inline-flex space-x-6">
                                        <span
                                            className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">What's new</span>
                                    <span
                                        className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
            <span>Just shipped v1.0</span>

                                            <svg className="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                    clip-rule="evenodd"/>
            </svg>
          </span>
                                </a>
                            </div>
                            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">Deploy to
                                the cloud with confidence</h1>
                            <p className="mt-6 text-lg leading-8 text-gray-300">Anim aute id magna aliqua ad ad non
                                deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam
                                occaecat fugiat aliqua.</p>
                            <div className="mt-10 flex items-center gap-x-6">
                                <a href="#"
                                   className="rounded-md bg-indigo-500 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400">Get
                                    started</a>
                                <a href="#" className="text-base font-semibold leading-7 text-white">Learn
                                    more <span aria-hidden="true">→</span></a>
                            </div>
                        </div>
                        <div
                            className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32">
                            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                                <img src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
                                     alt="App screenshot" width="2432" height="1442"
                                     className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"/>
                            </div>
                        </div>
                    </div>
                </div>


                <Rodape/>

            </>
        );
}


