import React from "react";
import {
  NavLink,
  Link,
  Routes,
  Route,
    Navigate,
  useParams,
  useNavigate
} from "react-router-dom";

import  {HomePage} from "./paginas/Home";
import  {CheckoutPage} from "./paginas/ecommerce/checkout";

import  {Error404Page} from "./paginas/Error404";
import {DadosBasicoPage} from "./paginas/ecommerce/dadosBasico";
import {ObrigadoPage} from "./paginas/ecommerce/obrigado";
function AppRoutes() {
  return (
      <Routes>

          <Route path="/" element={<HomePage />} />
          <Route path="/lojavirtual/dadosBasico" element={<DadosBasicoPage />} />
          <Route path="/lojavirtual/checkout" element={<CheckoutPage />} />
          <Route path="/lojavirtual/obrigado" element={<ObrigadoPage />} />
          <Route path="pagina-nao-encontrada-404" element={<Error404Page/>}/>
          <Route path="*" element={<Navigate to="/pagina-nao-encontrada-404" replace />} />

      </Routes>
  );
}

export default function App() {
  return (
        <AppRoutes />
  );
}
